import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

//createRoot is the new react version 18, then we need to pass in documeny.getElementById root
//this is the only part of the application where we're fetching from the DOM
const root = ReactDOM.createRoot(document.getElementById("root"));

// we can use the root by saying root.render and we will render self closing App component
root.render(<App />);
