import { Link } from "react-router-dom";
import { Typography, Card, CardContent, CardMedia } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

import {
  demoThumbnailUrl,
  demoVideoUrl,
  demoVideoTitle,
  demoChannelUrl,
  demoChannelTitle,
} from "../utils/constants";

// what inside video
// video: {
//         id: {
//              kind: "youtube#video"
//              videoId: "FHTbsZEJspU"
//             }
//         kind: "youtube#searchResult"
//         snippet: {
//              channelId: "UCmXmlB4-HJytD7wek0Uo97A"
//              channelTitle: "JavaScript Mastery"
//              description: "Master modern web development by building a responsive React JS application consisting of stunning video sections, custom ..."
//              liveBroadcastContent: "none"
//              publishTime: "2022-08-26T12:02:28Z"
//              publishedAt: "2022-08-26T12:02:28Z"
//              thumbnails: {
//                            default: {url: 'https://i.ytimg.com/vi/FHTbsZEJspU/default.jpg', width: 120, height: 90}
//                            high: {url: 'https://i.ytimg.com/vi/FHTbsZEJspU/hqdefault.jpg', width: 480, height: 360}
//                            medium: {url: 'https://i.ytimg.com/vi/FHTbsZEJspU/mqdefault.jpg', width: 320, height: 180}
//                          }
//              title: "Build and Deploy a Modern YouTube Clone Application in React JS with Material UI"
//                  }
//       }

// we can directly destructure the data like below
const VideoCard = ({
  video: {
    id: { videoId },
    snippet,
  },
}) => {
  return (
    <Card
      sx={{
        width: { xs: "100%", sm: "358px", md: "320px" },
        boxShadow: "none",
        borderRadius: 0,
      }}
    >
      <Link to={videoId ? `/video/${videoId}` : demoVideoUrl}>
        {/* the ? question mark is for us not het any errors, it will only be undefined */}
        <CardMedia
          image={snippet?.thumbnails?.high?.url}
          alt={snippet?.title}
          sx={{ width: { xs: "100%", sm: "358px", md: "320px" }, height: 180 }}
        />
      </Link>
      <CardContent sx={{ backgroundColor: "#1e1e1e", height: "106px" }}>
        <Link to={videoId ? `/video/${videoId}` : demoVideoUrl}>
          <Typography variant="subtitle1" fontWeight="bold" color="#FFF">
            {snippet?.title.slice(0, 60) || demoVideoTitle.slice(0, 60)}
          </Typography>
        </Link>
        <Link
          to={
            snippet?.channelId
              ? `/channel/${snippet?.channelId}`
              : demoChannelUrl
          }
        >
          <Typography variant="subtitle2" fontWeight="bold" color="gray">
            {snippet?.channelTitle || demoChannelTitle}
            <CheckCircle sx={{ fontSize: 12, color: "gray", ml: "5px" }} />
          </Typography>
        </Link>
      </CardContent>
    </Card>
  );
};

export default VideoCard;
