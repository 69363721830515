import { useState, useEffect } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { Sidebar, Videos } from "./"; //like this bcs we already in components folder
import { fetchFromAPI } from "../utils/fetchFromAPI";

// `` - means template string
const Feed = () => {
  const [selectedCategory, setSelectedCategory] = useState("New");
  const [videos, setVideos] = useState([]);
  // const [videos, setVideos] = useState(null);

  // useEffect(() => {
  //   setVideos(null);

  //   //we cannot simply put "const data = fetchfromapi..." bcs it returns a promise
  //   fetchFromAPI(`search?part=snippet&q=${selectedCategory}`) //q means query
  //     //to extract the data, need to attach .then function , once it return us promise result to successful return
  //     //with asynchronius function we have to chain .then
  //     .then((data) => setVideos(data.items));
  // }, [selectedCategory]); //if empty [] means it will load whenever we refresh the page
  // //we put selectedCategory in the dependency array to keeps change the value whenever we click on different button on the sidebar

  useEffect(() => {
    // setVideos(null);

    fetchFromAPI(`search?part=snippet&q=${selectedCategory}`).then((data) =>
      setVideos(data.items)
    );
  }, [selectedCategory]);

  console.log("viodeos in Feed", videos);

  return (
    //  sx with column means that usually/default its column
    <Stack sx={{ flexDirection: { sx: "column", md: "row" } }}>
      <Box
        sx={{
          height: { sx: "auto", md: "92vh" },
          borderRight: "1px solid #3d3d3d",
          ps: { sx: 0, md: 2 },
        }}
      >
        <Sidebar
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
        <Typography
          className="copyright"
          variant="body2"
          sx={{ mt: 1.5, color: "#fff" }}
        >
          Copyright 2022 Najwa Norijan
        </Typography>
      </Box>
      <Box p={2} sx={{ overflowY: "auto", height: "90vh", flex: 2 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          mb={2}
          sx={{ color: "white" }}
        >
          {selectedCategory} <span style={{ color: "#F31503" }}>videos</span>
        </Typography>

        <Videos videos={videos} />
      </Box>
    </Stack>
  );
};

export default Feed;
