import axios from "axios";

const BASE_URL = "https://youtube-v31.p.rapidapi.com";

const options = {
  //   method: "GET", we dont have to specify thsi get request bcs we're going to do it with axios
  //   url: "https://youtube-v31.p.rapidapi.com/search", //for url we can put any special variable
  params: {
    // relatedToVideoId: "7ghhRHRP6t4",
    // part: "id,snippet",
    // type: "video", for now we dont pass any params yet
    maxResults: "50",
  },
  headers: {
    "X-RapidAPI-Key": process.env.REACT_APP_RAPID_API_KEY,
    // "X-RapidAPI-Key": "b97059e895msh0833242af4fac02p10fddfjsnc33abc5b93e8",
    "X-RapidAPI-Host": "youtube-v31.p.rapidapi.com",
    //we can leave "X-RapidAPI-Host" as it is but for "X-RapidAPI-Key" we must save it in our environment variable
  },
};
//it will only accept 1 parameter which is url
export const fetchFromAPI = async (url) => {
  // -- ex of the url /baseUrl/getChannel or /baseUrl/getVideos
  // -- options object as the second parameter
  // -- we can use  const response = await axios.get...
  // or like below to immediately destructure the data
  const { data } = await axios.get(`${BASE_URL}/${url}`, options);
  return data;
};
