import { Stack } from "@mui/material";
import { Link } from "react-router-dom";
import SearchBar from "./SearchBar";
//import { logo } from "../utils/constants";
import image from "../utils/assets/images/ntubelogo.png"

const Navbar = () => (
  // sx prop means you can provide any styles to material ui components
  <Stack
    direction="row"
    alignItems="center"
    p={2}
    sx={{
      position: "sticky",
      background: "#000",
      top: 0,
      justifyContent: "space-between",
    }}
  >
    <Link to="/" style={{ display: "flex", alignItems: "center" }}>
      <img src={image} alt="logo" height={60} />
    </Link>
    <SearchBar />
  </Stack>
);

export default Navbar;
